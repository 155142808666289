export const STATUS_CANCELADO = -1;
export const STATUS_ATIVO = 1;
export const STATUS_FINALIZADO = 2;

export const STATUS_CARTA_PRECATORIA = {
    [STATUS_CANCELADO]: {label: 'Cancelado'},
    [STATUS_ATIVO]: {label: 'Ativo'},
    [STATUS_FINALIZADO]: {label: 'Finalizado'}
}

export function getStatusString (status) {
    const result = STATUS_CARTA_PRECATORIA[status]
    if (!result) return 'Status indefinido'
    return result.label
}

export const STATUS_CARTA_PRECATORIA_FORMATADO = [
    {label: 'Ativo', value: STATUS_ATIVO},
    {label: 'Cancelado', value: STATUS_CANCELADO},
    {label: 'Finalizado', value: STATUS_FINALIZADO},
]