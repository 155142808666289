export default function (id, processoId) {
    let window = 'processo.envolvido.' + id
    !this.$uloc.window.get(window) && this.$uloc.window.new({
        wid: window,
        title: 'Envolvido #' + id,
        width: '700',
        height: '400',
        minHeight: '300px',
        backdrop: false,
        move: true,
        clickOutside: true,
        windowClass: 'erp-window-modern v2',
        contentClass: '',
        props: {
            router: this.$router,
            id,
            processoId
        }
    }, () => import('./EnvolvidoProcesso'))
        .then((wid) => {
            console.log(wid)
            this.$uloc.window.listen(wid, {
                updated: (wid, data) => {
                    console.log('Simple Person created event')
                    this.load?.()
                }
            })
        }) // return wid
}
